import React from 'react';
import '../../style/imagedisplay.scss'; 

const ImageDisplay = ({ src, alt, className }) => {
  return (
    <div className={`image-container ${className}`}>
      <img
        src={src}
        alt={alt}
        className="image-display"
      />
    </div>
  );
};

export default ImageDisplay;
