import React from "react";
import "../../style/experiences.scss";
import experiencesDatas from "../../data/experiencesDatas";
import diplomasDatas from "../../data/diplomasDatas";
import { Link } from "react-router-dom";

function Experiences() {
  return (
    <nav className='experiences-container'>
      <div className='experiences-grid'>
        <div className='colonne-gauche'>
          <h2>Expériences professionnelles</h2>
          <div className='experiences-list'>
            {experiencesDatas.experiences.map((exp) => (
              <div key={exp.id} className='experiences-item'>
                <div className='years'>
                  <h3>{exp.endYear}</h3>
                  <h3 className='experiences-year'>{exp.startYear}</h3>
                </div>
                <div className='content'>
                  <h4>{exp.title}</h4>
                  <p className='place'>{exp.place}</p>
                  <p>{exp.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='colonne-droite'>
          <h2>Diplômes</h2>
          <div className='diplomas-list'>
            {diplomasDatas.diplomas.map((diplo) => (
              <div key={diplo.id} className='diplomas-item'>
                <div className='years'>
                  <h3>{diplo.endYear}</h3>
                  <h3 className='diplomas-year'>{diplo.startYear}</h3>
                </div>
                <div className='content'>
                  <h4>{diplo.title}</h4>
                  <p className='place'>{diplo.place}</p>
                  <p className='description'>{diplo.description}</p>
                </div>
              </div>
            ))}
            <div className='buttons-about'>
              <Link to='/contact' className='button-link'>
                Me contacter
              </Link>
              <a
                href='https://i.ibb.co/WcwzyVw/CV-BVN-D-veloppeur-2024.jpg'
                className='button-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Mon CV
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className='experiences-intro-text'>{experiencesDatas.intro.text}</p>
      </div>
    </nav>
  );
}

export default Experiences;
