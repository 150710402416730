const experiencesDatas = {
  intro: {
    text: `"Après plusieurs années en hôtellerie je me suis réorienté après en avoir fait 
    le tour, cherchant la satisfaction de voir concrètement le résultat de mon travail."`,
  },
  experiences: [
    {
      id: 1,
      startYear: "2022",
      endYear: "2023",
      title: "Voiturier / Concierge en hôtellerie",
      place: "Mariott Lyon Cité International - Lyon, France",
      description:
        "Gestion des véhicules, coordination des services pour répondre aux demandes des clients et gestion des situations en autonomie. Développement de la prise de décision rapide et de la capacité à organiser des tâches multiples.",
    },
    {
      id: 2,
      startYear: "2021",
      endYear: "2022",
      title: "Bagagiste / Voiturier / Équipier",
      place: "Golden Tulip Porto-Vecchio - Corse, France",
      description:
        "Personne à tout faire avec une capacité à réagir rapidement aux imprévus et à gérer divers services de manière proactive, tout en assurant un service client de qualité.",
    },
    {
      id: 3,
      startYear: "2017",
      endYear: "2020",
      title: "Bagagiste / Voiturier",
      place: "Hôtel Moby Dick - Corse, France",
      description:
        "Premier et dernier point de contact avec les clients, coordination avec les autres services de l'hôtel. Développement de compétences en communication et travail d'équipe dans un environnement dynamique.",
    },
  ],
};

export default experiencesDatas;
