import React, { useEffect, useState } from 'react';
import '../../style/skills.scss';

function ProgressBarItem({ now }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(now);
    }, 100);

    return () => clearTimeout(timer);
  }, [now]);

  return (
    <div className="progress-bar-container">
      <div className="custom-progress-bar">
        <div className="progress-bar-fill" style={{ width: `${width}%` }}></div>
      </div>
    </div>
  );
}

export default ProgressBarItem;
