import React, { useState, useEffect } from 'react';
import '../../style/backtotopbutton.scss';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
  
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const handleClick = () => {
      setIsClicked(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => setIsClicked(false), 300);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <button
        className={`back-to-top ${isVisible ? 'visible' : ''} ${isClicked ? 'clicked' : ''}`}
        onClick={handleClick}
      >
        ↑
      </button>
    );
  };
  
  export default BackToTopButton;
