const skillsDatas = [
    {
      category: "Front-End",
      skills: [
        { name: "HTML", level: 60 },
        { name: "CSS/SCSS", level: 60 },
        { name: "JavaScript", level: 40 },
        { name: "React", level: 50 },
      ],
    },
    {
      category: "Back-End",
      skills: [
        { name: "Node.js", level: 20 },
        { name: "Express", level: 20 },
        { name: "MongoDB", level: 20 },
      ],
    },
    {
      category: "Autres",
      skills: [
        { name: "Git / Github", level: 30 },
      ],
    },
  ];

  export default skillsDatas;