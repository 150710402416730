import React from "react";
import ContactForm from "../../components/ContactForm/contactform.jsx";
import "../../style/contact.scss";
import MenuResponsive from "../../components/MenuResponsive/menuresponsive.jsx";

function Contact() {
  return (
    <nav className="page-contact">
      <MenuResponsive />
      <div className="contact-resp">
      <div className="texte-contact">
        <h1 className="titre-contact">Me contacter</h1>
        <p className="texte1-contact">
          Si vous souhaitez en savoir davantage sur mon parcours, veuillez
          remplir les informations ci-contre et je vous contacterai sous peu !
        </p>
        <p className="text2-contact">
          Ou si vous préférez, contactez-moi par e-mail:{" "}
          <b>b.vincent.neveu@gmail.com</b>
        </p>
        <p>
          et par téléphone au <b>06 80 34 69 51</b>
        </p>
      </div>
      <ContactForm />
      </div>
    </nav>
  );
}

export default Contact;
