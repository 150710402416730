const diplomasDatas = {
  diplomas: [
    {
      id: 1,
      endYear: "2024",
      title: "Formation à distance - OpenClassrooms",
      description: "Développeur Web",
    },
    {
      id: 2,
      startYear: "2017",
      endYear: "2020",
      title: "BTS Hôtellerie restauration - CCI Dordogne",
      place: "Boulazac, France",
      description: "BTS Hôtellerie Restauration - Option A",
    },
    {
      id: 3,
      startYear: "2013",
      endYear: "2017",
      title: "Bac Professionnel - Lycée Saint Jean",
      place: "Limoges, France",
      description: "Bac Professionnel de cuisine",
    },
  ],
};

export default diplomasDatas;
