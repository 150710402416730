import React from "react";
import "../../style/footer.scss";
import logo from "../../assets/logo.webp";

function Footer() {
  return (
    <footer>
      <img className="logo" src={logo} alt="logo benjamin" />
      <p className="footer">© 2024 Benjamin Vincent-Neveu</p>
    </footer>
  );
}

export default Footer;
