import { faHtml5, faCss3Alt, faJs, faReact, faSass, faNodeJs } from "@fortawesome/free-brands-svg-icons";
import mongodbLogo from "../assets/mongodb.svg";
import expressLogo from "../assets/express.svg"

const projetsDatas = [
  {
    id: "1",
    title: "Booki",
    description: "L'objectif de ce projet était de développer la page d'accueil du site Booki uniquement en HTML et CSS en suivant les directives d'une maquette. Il était ensuite demandé de faire le responsive de cette page.",
    cover: "Développement du site internet de l'agence de voyage Booki.",
    picture: "https://i.ibb.co/1bnVwfc/booki-screenshotprojet1.png",
    technology: [
      { icon: faHtml5 },
      { icon: faCss3Alt },
    ],
    overview: "https://bvn8.github.io/Projet-2-Booki/#hebergements",
    github: "https://github.com/BVN8/Projet-2-Booki",
  },
  {
    id: "2",
    title: "Sophie Bluel",
    description:
      "Le projet consistait à développer la partie dynamique du portfolio d'une architecte d'intérieur grâce au JavaScript. Mais aussi établir une connexion front-end / back-end grâce à une API. Elle était utilisée pour récupérer et afficher les projets de l'artiste.",
    cover:
      "Développement d'une page web dynamique pour l'architecte Sophie Bluel.",
      picture: "https://i.ibb.co/RCHTNx4/sophiebluel-screeshot-projet2.png",
      technology: [
        { icon: faHtml5 },
        { icon: faCss3Alt },
        { icon: faJs },
      ],
      github: "https://github.com/BVN8/Projet-2-Booki",
  },
  {
    id: "3",
    title: "Menu Maker by Qwenta",
    description:
      "En tant que responsable agile sur le projet Menu Maker by Qwenta, l'objectif était de trouver une solution de développement optimale. Cela s'est séparé en 3 parties : La veille technique, les spécifications techniques avec les choix de technologies et enfin la gestion de projet avec Trello et la méthode Agile.",
    cover:
      "Planification du développement du site Web sans intervenir dans le code.",
      picture: "https://i.ibb.co/YhBxBW3/menumakerqwenta-screenshot-projet3.png",
    overview: "https://trello.com/b/c423sKVt/tableau-menu-maker-by-qwenta",
  },
  {
    id: "4",
    title: "Nina Carducci",
    description:
      "Ici, il n'était pas question de code. En effet, le but était d'optimiser que ça soit par la vitesse de chargement, le SEO ou l'accessibilité, le portfolio d'une photographe grâce à des outils tels que WAVE, Lighthouse.",
    cover:
      "Réalisation d'un audit afin d'améliorer les performances du site de Nina Carducci.",
      picture: "https://i.ibb.co/fHvZ13F/ninacarducci-screenshot-projet5.png",
      github: "https://github.com/BVN8/Projet5-Nina-Carducci",
  },
  {
    id: "5",
    title: "Kasa",
    description:
      "Le projet était de développer une application de location en ligne avec React. Découvrir ce que pouvait offrir cette technologie tels que les hooks, les composants ou bien les routes. De plus, il était nécessaire d'utiliser Sass ce qui a permet de découvrir un nouveau language.",
    cover:
      "Développement de l'application de location d'appartements en ligne de Kasa.",
      picture: "https://i.ibb.co/nzVLRkc/kasa-screenshot-projet6.png",
      technology: [
        { icon: faReact },
        { icon: faSass },
      ],
      overview: "https://misterdougi.github.io/kasa_projet6_oc/",
      github: "https://github.com/BVN8/Projet-6-Kasa/tree/master",
  },
  {
    id: "6",
    title: "Mon vieux Grimoire",
    description:
      "L'objectif était de réaliser le backend du site de notation de livres Mon Vieux Grimoire. Il était demandé d'utiliser Node.js et son framework Express. Les principales fonctionnalités comprenaient la création d'un espace utilisateur, la gestion des connexions utilisateur, la possibilité d'ajouter de nouveaux livres, ainsi que de les modifier ou de les supprimer.",
    cover:
      "Développement du backend du site de notations de livres Mon Vieux Grimoire.",
      picture: "https://i.ibb.co/ySvgJ67/grimoire-screenshot-projet7.png",
      technology: [
        { icon: faNodeJs },
        { icon: mongodbLogo },
        { icon: expressLogo }
      ],
      github: "https://github.com/BVN8/Projet-7-Grimoire",
  },
  {
    id: "7",
    title: "Mon Portfolio",
    description:
      "Le dernier projet de cette formation comprenait un portfolio. J'ai décidé de le réalisé avec React et Sass car j'ai beaucoup apprécié et me suis senti à l'aise sur ces technologies. Ce projet est, bien sûr, amené à changer avec de nouvelles intégrations. ",
    cover:
      "La première version (sûrement pas la dernière !) de mon portefolio.",
      picture: "https://i.ibb.co/XktsQzS/portfolio-screeshot-projet8.png",
      technology: [
        { icon: faReact },
        { icon: faSass },
      ],
      overview: "",
      github: "",
  },
  {
    id: "8",
    title: "Kokkomoon",
    description:
      "",
    cover:
      "Le site d'une tatoueuse pleine de talent. Stay tuned !",
      picture: "",
      technology:"",
      overview: "",
      github: "",
  }
];

export default projetsDatas;