import React from "react";
import "../../style/about.scss";
import Experiences from "../../components/Experiences/experiences.jsx";
import Skills from "../../components/Skills/skills.jsx";
import Personnal from "../../components/Personnal/personnal.jsx"
import MenuResponsive from "../../components/MenuResponsive/menuresponsive.jsx";
import BackToTopButton from "../../components/BackToTopButton/backtotopbutton.jsx";

function About() {
  return (
    <main className="background-about-page">
      <nav className="about-container">
      <MenuResponsive />
        <h1>Compétences</h1>
        <div>
        <Skills />
        </div>
        <h1>Expériences</h1>
        <div>
        <Experiences />
        </div>
        <h1>À propos de moi</h1>
        <div>
        <Personnal />
        </div>
      </nav>
      <BackToTopButton />
    </main>
  );
}

export default About;
