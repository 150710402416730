import React from "react";
import { Link } from "react-router-dom";
import projetsDatas from "../../data/projetsDatas.jsx";
import fleche from "../../assets/fleche-projets.png";
import "../../style/card.scss";
import MenuResponsive from "../../components/MenuResponsive/menuresponsive.jsx";

function CardProjets() {
  return (
    <nav>
      <div className="header-projet">
        <h1 className="title-projet">Mes projets</h1>
        <img className="fleche-projets" src={fleche} alt="flèche" />
      </div>
      <MenuResponsive />
      <div className="listCardDisplay">
        {projetsDatas.map((projets) => (
          <Link
            key={projets.id}
            to={`/projets/${projets.id}`}
            className="outerContainer"
          >
            <div className="innerCard">
              <h2 className="title-container">{projets.title}</h2>
              <p>{projets.cover}</p>
            </div>
            <div>
              <p className="bottom-text">Voir le projet</p>
            </div>
          </Link>
        ))}
      </div>
    </nav>
  );
}

export default CardProjets;
