import React from "react";
import "../../style/personnal.scss";
import photo from "../../assets/image.jpg";

function Personnal() {
  return (
    <nav className='personnal-container'>
      <div>
        <img src={photo} alt='photo-benjamin' />
      </div>
      <p>
        Je m'appelle Benjamin Vincent-Neveu.
        <br />
        Auparavant, j'étais dans la restauration. J'ai travaillé dans plusieurs
        établissements, allant du restaurant de campagne au restaurant étoilé
        Michelin, puis je me suis dirigé vers l'hôtellerie, où j'ai eu la chance
        de travailler dans des chaînes haut de gamme telles qu'Accor, Louvre
        Hôtels ou Marriott. <br />
        Un jour, par manque de défis, j'ai décidé de m'inscrire à la formation
        Développeur Web en ligne d'OpenClassrooms pour apprendre un nouveau
        métier, changer mon avenir et vivre d'autres aventures.
      </p>
    </nav>
  );
}

export default Personnal;
