import React from "react";
import ProgressBarItem from "../ProgressBar/progressbaritem.jsx";
import skillsDatas from "../../data/skillsDatas.jsx";
import "../../style/skills.scss";

function Skills() {
  return (
    <section className="skills-section">
      <div className="skills-grid">
        {skillsDatas.map((category) => (
          <div key={category.category} className="skills-category">
            <h2>{category.category}</h2>
            {category.skills.map((skill) => (
              <div key={skill.name} className="skill-item">
                <p>{skill.name}</p>
                <ProgressBarItem now={skill.level} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Skills;
