import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareGithub } from '@fortawesome/free-brands-svg-icons';
import "../../style/menuresponsive.scss";

function MenuResponsive() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <>
      <div 
        className={`burger-icon ${isMenuOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
      >
        <div className="burger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/" className="menu-link" onClick={toggleMenu}>Accueil</Link>
        <Link to="/apropos" className="menu-link" onClick={toggleMenu}>À propos</Link>
        <Link to="/projets" className="menu-link" onClick={toggleMenu}>Projets</Link>
        <Link to="/contact" className="menu-link" onClick={toggleMenu}>Contact</Link>
        <a 
          href="https://github.com/BVN8?tab=repositories" 
          target="_blank" 
          rel="noopener noreferrer"
          className="github-footer"
        >
          <FontAwesomeIcon icon={faSquareGithub} style={{ color: "#664225", fontSize: "40px" }} />
        </a>
      </div>
    </>
  );
}

export default MenuResponsive;
