import React from "react";
import "../../style/header.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.webp";
import logogithub from "../../assets/logo-github.webp";

function Header() {
  return (
    <nav className="header">
      <img className="logo" src={logo} alt="logo benjamin" />
      <div className="links-header">
        <Link to="/" className="header-links">Accueil</Link>
        <Link to="/apropos" className="header-links">À propos</Link>
        <Link to="projets" className="header-links">Projets</Link>
        <Link to="contact" className="header-links">Contact</Link>
      </div>
      <a
        href="https://github.com/BVN8?tab=repositories"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logogithub} alt="GitHub Logo" className="github-logo" />
      </a>
    </nav>
  );
}

export default Header;
