import React from 'react';
import '../../style/contact.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const schema = yup
    .object({
      name: yup
        .string()
        .max(50)
        .required('Merci de rentrer votre nom et prénom'),
      email: yup
        .string()
        .email('Merci de rentrer une adresse mail valide')
        .max(255)
        .required('Merci de rentrer une adresse mail'),
      message: yup.string().required('Merci de rentrer un message'),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    alert('Merci pour votre message, il sera traité au plus vite !');
    const templateId = 'template_ka5v5ow';
    const serviceId = 'service_t3g1yze';

    sendFeedback(serviceId, templateId, {
      name: data.name,
      email: data.email,
      message: data.message,
      reply_to: data.email,  
    });

    reset(); 
  };

  const sendFeedback = (serviceId, templateId, variables) => {
    emailjs
      .send(serviceId, templateId, variables, 'fdggVXXXd0NeygQHq') 
      .then((res) => {
        console.log('Email envoyé avec succès', res);
      })
      .catch((err) => console.error('Erreur lors de l\'envoi de l\'email', err));
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-content">
        <label htmlFor="name" className="label-contact">Nom et Prénom :</label>
        <input
          className="input-contact"
          type="text"
          id="name"
          name="name"
          placeholder="Nom et Prénom"
          {...register('name')}
        />
        {errors.name && <p id="c-yup">{errors.name.message}</p>}
        
        <label htmlFor="email" className="label-contact">Adresse mail :</label>
        <input
          className="input-contact"
          type="email"
          id="email"
          name="email"
          placeholder="exemple@gmail.com"
          {...register('email')}
        />
        {errors.email && <p id="c-yup">{errors.email.message}</p>}
        
        <label htmlFor="message" className="label-contact">Message :</label>
        <textarea
          className="message-contact"
          placeholder="Écrivez votre message"
          id="message"
          cols="20"
          rows="10"
          name="message"
          {...register('message')}
        ></textarea>
        {errors.message && <p id="c-yup">{errors.message.message}</p>}
        
        <button className="button-contact" type="submit">Envoyer</button>
      </div>
    </form>
  );
};

export default Contact;
