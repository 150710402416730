import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../style/projetdetails.scss";
import projetsDatas from "../../data/projetsDatas.jsx";
import ImageDisplay from "../../components/ImageDisplay/imagedisplay.jsx";
import Technologies from "../../components/Technologies/technologies.jsx";

function ProjetDetails() {
  const { projetsId } = useParams();
  const projet = projetsDatas.find((projet) => projet.id === projetsId);
  const navigate = useNavigate();

  return (
    <nav className="projet-details">
      <div className="grid-container">
        <div className="left-column">
          <button className="return-button" onClick={() => navigate(-1)}>
            &lt; Retour
          </button>
          <h1 className="title-project">{projet.title}</h1>
          <p className="scroll">psst, try to scroll it</p>
          <ImageDisplay
            src={projet.picture}
            alt="site_preview"
            className="image-display"
          />
        </div>
        <div className="right-column">
          <h2>Description</h2>
          <p>{projet.description}</p>
          {projet.technology && projet.technology.length > 0 && (
            <>
              <h2>Languages</h2>
              <Technologies technologies={projet.technology} />
            </>
          )}
          <div className="link-project">
            {projet.overview && (
              <a
                href={projet.overview}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Aperçu</button>
              </a>
            )}
            {projet.github && (
              <a href={projet.github} target="_blank" rel="noopener noreferrer">
                <button>Lien GitHub</button>
              </a>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default ProjetDetails;
