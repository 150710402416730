import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import "../../style/error.scss";
import { useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();

  return (
    <div className="error_page_background">
      <div className="error_content">
        <p>
          Ce projet est en cours de développement ! Si vous souhaitez accéder à
          son travail, vous pouvez la retrouver sur son Instagram
          <a
            href="https://www.instagram.com/kokkomoon.tattoo/"
            target="_blank"
            rel="noopener noreferrer"
            className="lien_instagram"
          >
            kokkomoon.tattoo
            <FontAwesomeIcon icon={faInstagram} className="instagram_icon" />
          </a>
        </p>
        <button className="return-button" onClick={() => navigate(-1)}>
          &lt; Retour
        </button>
      </div>
    </div>
  );
}

export default Error;
