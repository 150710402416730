import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/technologies.scss';

const Technologies = ({ technologies }) => {
  return (
    <div className="technology-icons">
      {technologies && technologies.map((tech, index) => (
        <div key={index} className="tech-icon">
          {tech.icon && typeof tech.icon === 'object' ? (
            <FontAwesomeIcon icon={tech.icon} />
          ) : (
            <img src={tech.icon} alt="" className="tech-logo" />
          )}
        </div>
      ))}
    </div>
  );
};

export default Technologies;
