import React from "react";
import "../../style/projets.scss";
import CardProjets from "../../components/Card/card";

function Projet() {
  return (
      <div className="projets">
        <CardProjets />
      </div>
  );
}

export default Projet;
